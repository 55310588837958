/**
 * create by wangchunyan1 on 2019/6/14
 */
import React from "react";
import "./policyYd.less";

import SlideImg from "../../components/slideImgs/slideImg";

class PolicyYd extends React.Component {

  render() {
    return (
      <div className='policyBoxYd'>
        <h3 className='commonTitleH3Yd'>政策持续利好</h3>
        <p className='commonTitlePYd'>学习变简单，教育更公平</p>
        <h4 className='commonTitleH4Yd'>
          2015年李克强总理首次提出"互联网+"，<br/>教育信息化进入加速发展的新阶段。
        </h4>
        <div className='policyInnerBoxYd'>
          <SlideImg from={"comp-policy"}/>
        </div>
      </div>
    );
  }
}

export default PolicyYd;
