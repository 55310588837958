/**
 * create by wangchunyan1 on 2019/6/14
 */
// import { Link } from "gatsby";
import React from "react";
import "./bannerYd.less";
import Message from '../message/message';
import bannerBoxYd2 from "../../images/bannerAgentYd.jpg";
import bannerBoxYd1 from "../../images/bannerMasterYd.jpg";
import bannerBoxYd0 from "../../images/bannerTeacherYd.jpg";
import { isInWindow } from "../../utils/tools";

class Banner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      bannerIndex: 0,
      isShowMessage: false
    };
  }

  timer = null;

  componentDidMount() {
    this.countDown();
  }

  componentWillMount() {
    clearInterval(this.timer);
  }

  countDown() {
    const self = this;
    this.timer = setInterval(() => {
      let index = self.state.bannerIndex;
      index = (index === 2 ? 0 : ++index);
      this.setState({
        bannerIndex: index
      });
    }, 5000);
  }

  knowDetail(bannerIndex) {
    if(bannerIndex === 2){
      this.setState({
        isShowMessage: true
      });
      setTimeout(() => {
        this.setState({
          isShowMessage: false
        });
      }, 3000);
    }else{
      let url = '';
      url = bannerIndex === 0 ? 'teacher' :'master';
      if (isInWindow()) {
        window.location.href = `/${url}y`;
      }
    }

  }

  render() {
    return (
      <div className='innerBoxYd'>
        {
          this.state.bannerIndex === 0 ?
            <img src={bannerBoxYd0} alt='图片'/> :
            this.state.bannerIndex === 1 ? <img src={bannerBoxYd1} alt='图片'/> : <img src={bannerBoxYd2} alt='图片'/>
        }
        <div className='knowDetailYd' onClick={this.knowDetail.bind(this,this.state.bannerIndex)}>了解详情</div>
        {
          this.state.isShowMessage && <Message info='内容正在维护，敬请期待！'/>
        }
      </div>
    );
  }
}

export default Banner;
