/**
 * create by wangchunyan1 on 2019/6/22
 */
import React from "react";
import { graphql } from "gatsby";

import "./homey.less";

import LayoutYd from "../components/layoutYd";
import BannerYd from "../components/banner/bannerYd";
import VideoBoxYd from "../components/videoBox/videoBoxYd";
import PolicyYd from "../components/policy/policyYd";
import AdvantageYd from "../components/advantage/advantageYd";
import ElegantDemeanorYd from "../components/elegantDemeanor/elegantDemeanorYd";
import NewsYd from "../components/news/newsYd";
import AboutYd from "../components/about/aboutYd";
import ContactUsYd from "../components/contactUs/contactUsYd";
import TipsYd from "../components/tips/tipsYd";

const HomeY = (props) => {
  const data = props.data.allWordpressPost.edges;
  return (
    <LayoutYd>
      <BannerYd/>
      <VideoBoxYd/>
      <div className='advantageBoxYd'>
        <h3 className='commonTitleH3Yd'>核心优势显著</h3>
        <p className='commonTitlePYd'>学习变简单，教育更公平</p>
        <h4 className='advantageThirdTitle'>
          简单智课堂在重构学校较重流程中，不断更新精英教师<br/>资源，不断改进经验，完善题库。<br/>根据学校的应用模式和十几环境配置，调整教学应<br/>交部署，并完善教学科研支持服务，逐渐形成智课<br/>堂四大核心优势，大大提升其竞争力。
        </h4>
        <AdvantageYd/>
      </div>
      <ElegantDemeanorYd/>
      <NewsYd data={data}/>
      <AboutYd/>
      <ContactUsYd/>
      <TipsYd/>
    </LayoutYd>
  );
};
export default HomeY;
export const indexQueryY = graphql`
query indexQueryY {
    allWordpressPost(sort: {fields: modified, order: DESC},limit: 3) {
    edges {
      node {
        id
        title
        excerpt
        link
        modified( formatString: "YYYY.MM.DD" )
        featured_media {
        source_url
      }
      }
    }
  }
}
`;
