/**
 * create by wangchunyan1 on 2019/6/14
 */
import React from "react";
import "./videoBoxYd.less";
import poster from "../../images/pic_videoPosterYd.png";
import { Video } from "../../utils/constant";


const VideoBoxYd = () => (
  <div className='videoBoxYd'>
    <h3 className='commonTitleH3Yd'>智课堂视频介绍</h3>
    <p className='commonTitlePYd'>学习变简单，教育更公平</p>
    <div className='videoContainerYd'>
      <video className='ydVideo' preload="meta" controls poster={poster} controlsList='nodownload' loop>
        <source src={Video.Trailer}
                type="video/mp4"/>
        你的浏览器不支持 html5 video播放
      </video>
    </div>
  </div>
);
export default VideoBoxYd;
