/**
 * create by wangchunyan1 on 2019/6/14
 */
import React from "react";
import SlideImg from "../../components/slideImgs/slideImg";
import ElegantDemeanorImgYd from "../../components/elegantDemeanor/elegantDemeanorImgYd";
import "./elegantDemeanorYd.less";

class ElegantDemeanorYd extends React.Component {

  render() {
    return (
      <div className='elegantDemeanorYd'>
        <h3 className='commonTitleH3Yd'>使用风采展示</h3>
        <p className='commonTitlePYd'>
          学习变简单，教育更公平
        </p>
        <ElegantDemeanorImgYd/>
        <div className='elegantDemeanorInnerBox'>
          <SlideImg from={"comp_elegantDemeanorYd"}/>
        </div>
      </div>
    );
  }
}

export default ElegantDemeanorYd;
