/**
 * create by wangchunyan1 on 2019/6/14
 */
// import { Link } from "gatsby";
import React from "react";
import "./aboutYd.less";

const AboutYd = () => {
  return (
    <div className="aboutBoxYd">
      <h3 className='commonTitleH3Yd'>关于简单科技</h3>
      <p className='commonTitlePYd'>学习变简单，教育更公平</p>
      <div className="aboutListYd">
        <dl className='aboutItemYd'>
          <dt><span>14</span><label>成立14年</label></dt>
          <dd>成立于2007年，创始团队主要来自北京大学，斯坦福大学，核心技术和北京大学共同申请的国家专利技术。</dd>
        </dl>
        <dl className='aboutItemYd'>
          <dt><span>700</span><label>学习中心</label></dt>
          <dd>14年来，可累计了20万节精品微课，发展了700多家学习中心。</dd>
        </dl>
        <dl className='aboutItemYd'>
          <dt><span>2400</span><label>万学员</label></dt>
          <dd>简单科技旗下品牌简单学习网累计注册学员2400万。</dd>
        </dl>
      </div>
    </div>
  );
};
export default AboutYd;
