/**
 * create by wangchunyan1 on 2019/8/9
 */
import React from "react";
import "./elegantDemeanorImgYd.less";
import dataCiY from '../../images/pic_dataCiY.png';
import dataDaoY from '../../images/pic_dataDaoY.png';
import dataShengY from '../../images/pic_dataShengY.png';

const ElegantDemeanorImgYd = () => (
  <div>
    <div className='elegantDemeanorContainerYd'>
      <div className='elegantDemeanorList'>
        <dl className='elegantDemeanorItem'>
          <dt><img src={dataShengY} alt={'省'}/></dt>
          <dd>13个省、数十家基地示范校。</dd>
        </dl>
        <dl className='elegantDemeanorItem'>
          <dt><img src={dataCiY} alt={'次'}/></dt>
          <dd>老师单校单周使用频次达7.58次。</dd>
        </dl>
        <dl className='elegantDemeanorItem'>
          <dt><img src={dataDaoY} alt={'道'}/></dt>
          <dd>学生单科单周课上完成题目数达48道。<br/></dd>
        </dl>
      </div>
    </div>
  </div>
);
export default ElegantDemeanorImgYd;
