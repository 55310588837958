/**
 * create by wangchunyan1 on 2019/6/14
 */
import React from "react";
import "./newsYd.less";
import rightArrow from '../../images/icon_grayCircelArrow.png';

const NewsYd = (props) => {

  const data = props.data;
  return (
    <div className='newsBoxYd'>
      <h3 className='commonTitleH3Yd'>品牌动态</h3>
      <p className='commonTitlePYd'>学习变简单，教育更公平</p>
      <div className='newsContainer'>
        {
          data && data.map((item) => {
            let date = item.node.modified.split(".");
            let imgUrl = (item.node.featured_media && item.node.featured_media.source_url) || "";
            return (
              <dl key={`newsItem${item.node.id}`}>
                <a href={item.node.link} target='_blank' rel="noopener noreferrer">
                  <dt>
                    <img className='image1' src={imgUrl} alt='' />
                    <h5><div dangerouslySetInnerHTML={{ __html: item.node.title }}/></h5>
                    <div className='content'>
                      <div dangerouslySetInnerHTML={{ __html: item.node.excerpt }}/>
                    </div>
                  </dt>
                  <dd className='newsItemFooter'>
                    <div className='newsItemFooterLeft'>
                      <span>{date[2]}</span>
                      <label>{`${date[0]}.${date[1]}`}</label>
                    </div>
                    <div className='newsItemFooterRight'>
                      <img  className='goDetail' src={rightArrow} alt={'箭头'}/>
                    </div>
                  </dd>
                </a>
              </dl>
            );
          })
        }
      </div>
    </div>
  );
};

export default NewsYd;
